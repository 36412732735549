import styled from '@emotion/styled'
import { Controls, VideoProps, withVideoControls } from 'cuenect-web-core'
import { navigate } from 'gatsby'
import parse from 'html-react-parser'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentContainer } from '../components'
import {
  ImageTeaser,
  InnerContainer,
  Heading,
  LineContainer,
  Paragraph,
  Button,
} from '../components'
import { LoadingIndicator } from '../components/atoms/loadingIndicator'
import {
  AppointmentForm,
  AppointmentFormSent,
} from '../components/organisms/appointmentForm'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { AppointmentService, TopicsService } from './../api'
import { isPostEvent } from './../config'
import { mq } from './../utility/breakpoint'
import { useModalContext } from './../utility/modalContext'

const DialogPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params
  const [loaded, setLoaded] = React.useState(false)
  const [topics, setTopics] = React.useState()
  const { t } = useTranslation('dialog')
  const { dispatch: modalDispatch } = useModalContext()
  const appointmentFormRef = React.useRef<HTMLFormElement>(null)
  const fieldRef = React.useRef<HTMLDivElement>(null)
  const isPost = isPostEvent()

  if (lang === '') {
    if (typeof window !== 'undefined') {
      navigate(`/en/${document.location.search}`)
    }

    return <></>
  }

  useEffect(() => {
    if (
      loaded &&
      topics &&
      location.hash === '#appointment' &&
      fieldRef.current
    ) {
      fieldRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [loaded, topics])

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  useEffect(() => {
    ;(async () => {
      const newTopics = await TopicsService.getTopics()

      console.log({ newTopics })

      setTopics(newTopics)
    })()
  }, [])

  const sendForm = data => {
    modalDispatch({
      type: 'OPEN_WITH_COMPONENT',
      state: {
        component: LoadingIndicator,
      },
    })

    AppointmentService.sendForm(data, topics).then(({ status }: any) => {
      modalDispatch({
        type: 'OPEN_WITH_COMPONENT',
        state: {
          component: AppointmentFormSent,
          params: {
            success: status === 204,
            onClose: (success: any) => {
              if (success) {
                if (!appointmentFormRef.current) {
                  return
                }

                appointmentFormRef.current.reset()
              }
            },
          },
        },
      })
    })
  }

  return (
    <PageFrame {...params} pageName="dialog" perectScroll={false}>
      {loaded && (
        <>
          <ContentContainer>
            <InnerContainer>
              <LineContainer className="LineContainer">
                <Heading>
                  {isPost ? t('headlinePhase4') : t('headline')}
                </Heading>
                <Paragraph style={{ marginBottom: 0 }}>
                  {isPost ? parse(t('sublinePhase4')) : parse(t('subline'))}
                </Paragraph>
              </LineContainer>
            </InnerContainer>

            <EmptyTeaserContainer>
              <EmptyTeaserImageContainer>
                <ImageTeaser imageUrl="/assets/index/03.jpg"></ImageTeaser>
              </EmptyTeaserImageContainer>

              <EmptyTeaserCopyContainer>
                <div>
                  <Paragraph>
                    {isPost ? parse(t('copyPhase4')) : parse(t('copy'))}
                  </Paragraph>
                  {isPost && (
                    <Button onClick={() => window.open(t(`ctaPhase4.link`))}>
                      {t(`ctaPhase4.label`)}
                    </Button>
                  )}
                </div>
              </EmptyTeaserCopyContainer>
            </EmptyTeaserContainer>
            <Spacer />
          </ContentContainer>
          {topics && !isPost && (
            <>
              <div ref={fieldRef}></div>
              <ContentContainer>
                <AppointmentForm
                  topics={topics}
                  ref={appointmentFormRef}
                  onSubmit={data => sendForm(data)}
                />
              </ContentContainer>
            </>
          )}
        </>
      )}
    </PageFrame>
  )
}

export default DialogPage

const EmptyTeaserContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: '2rem 0',

  [`${mq[2]}`]: {
    flexDirection: 'row',
  },
})

const EmptyTeaserImageContainer = styled.div({
  [`${mq[2]}`]: {
    flex: 30,
  },
})

const EmptyTeaserCopyContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: '1rem 0',

  [`${mq[2]}`]: {
    flex: 70,
    padding: '0 2rem',
  },
})

const Spacer = styled.div`
  margin: 95px 0;
  ${mq[3]} {
    margin: 125px 0;
  }
`
